@media only screen and (max-width: 467px) {

  #ourvision {
    margin-top: 2rem !important;
  }

  .primary-line-btn {
    @include custom-button(transparent, $primary, 10pt, 600, .8rem 2rem, 1px solid $primary, 0, $primary, $white, 1px solid $primary)
  }

  .img-sm-center {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .nav-bar-top {
    .navbar {
      .navbar-nav {
        flex-direction: row;
      }
    }
  }

  .search-form {
    max-width: 100% !important;
    min-width: 100% !important;
    padding-top: 0.4rem;
    margin-right: 0 !important;
  }

  .nav-bar-main {
    .dropdown-link {
      &:hover {
        .mega-content {
          height: max-content !important;
        }
      }
    }
  }

  .nav-bar-search .search-form .custom-form .form-control {
    margin-left: 0;
    margin-top: 1rem;
  }
  .nav-bar-search .search-form .custom-form .search-btn {
    right: -1rem;
    top: .6rem;
  }

  .sm-img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .home-page {

    #homeslider ul.image_slider_ul li .bgimage {
      width: 100%;
      height: 100%;
      position: relative;
      background-position: 15% 0 !important;
      background-size: cover;
      background-repeat: no-repeat;
      .carousel-caption {
        right: 31% !important;
        bottom: 20px !important;
        left: 3% !important;
      }
      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        opacity: .7;
        background-color: $dark;
        width: 100%;
        height: 100%;
      }
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle {
      margin-bottom: 1rem;
      padding: 0 !important;
      margin-left: 0rem;
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle .main-title {
      margin-bottom: 1rem;
      color: #69BEB1;
      font-weight: 700;
      font-size: 1.9rem;
    }

    .our-partners {
      .owl-carousel {
        .owl-height {
          height:250px;

          .item {
            margin-left: 0;
            margin-right: 0;
            img {
              height: 250px;
              width: 250px;
              display: block;
              margin-right: auto;
              margin-left: auto;
              border-radius: 50%;
              border: 9px solid lightgray;
            }


          }
        }

      }
    }


    // Quick Category Section
    .quick-category {
      .white-card {
        @include custom-card($white, 2rem 1rem, 2rem 0 2rem 0, $box-shadow);
        position: relative;

        .category-items {
          a {
            @include link($gray-300, 12pt, 500, none, $secondary);
          }
        }
      }
    }

    // Card Section
    .card-section {
      .gray-card {
        @include custom-card($bg-gray, 1.5rem 2rem, 1rem 0, none);

        h1 {
          @include font($dark, 12pt, 700);
          text-transform: uppercase;

          span {
            font-weight: 700;
          }
        }

        p {
          @include font($gray-400, 9pt, 500);
          @include limit(2);
        }

        .border-less-btn {
          @include custom-button(transparent, $primary, 11pt, 600, 1rem 0, none, 0, transparent, $secondary, none);
          text-transform: uppercase;

        }
      }
    }

    .card-section-one {
      .white-card {
        padding-right: 0;
        padding-left: 0;

        .gray-card {
          @include custom-card($bg-gray, 1.5rem 2rem, 1rem 1rem, none);

          h1 {
            @include font($dark, 12pt, 700);
            text-transform: uppercase;

            span {
              font-weight: 700;
            }
          }

          p {
            @include font($gray-400, 9pt, 500);
            @include limit(2);
          }

          .border-less-btn {
            @include custom-button(transparent, $primary, 11pt, 600, 1rem 0, none, 0, transparent, $secondary, none);
            text-transform: uppercase;

          }
        }
      }
    }

    #ourvision {
      margin-top: 2rem !important;
    }

    .our-testimonials {
      .owl-slider {
        .item {
          margin-bottom: 1rem;
          .white-card {
            @include custom-card($white, 1rem, 0, none);
            border: none;
            transform: translateY(4rem);
            transition: .5s all ease-in-out;

            .media {
              flex-direction: column;
              .quoat {
                color: $danger-light;
                font-size: 30px;
                position: absolute;
                right: 1rem;
                top: 1rem;
                display: block;
                z-index: 9999;
              }

              .img-tes {
                width: 102px !important;
                height: 102px !important;
                border: 5px solid $white;
                box-shadow: $box-shadow;
                border-radius: 50%;
                display:block;
                margin-right: auto !important;
                margin-left: auto !important;
              }
            }

            .details {
              margin-top: 1rem;
              padding-top: 1rem;
              border-top: 1px solid $gray-100;
              opacity: 1;
              transform: translateY(-1rem);
              transition: 1s all ease-in-out;
            }
          }
        }
      }
    }
  }
// Donation nav
  #donate-now-footer{
    background: #69BEB1;
    bottom: 0;
    box-shadow: 5px 0 15px rgba(0,0,0,.2);
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 99999;
    .btn {
      background: #EED779;
      border-radius: 25px;
      font-size: 12px;
      font-family: "american" !important;
      padding: .375rem .75rem;
    }
    p {
      margin: 0;
    }
  }

  //specifications

  .specifications {
    .media {
      flex-direction: row-reverse;
      text-align: left;
    }
  }

  // footer
  .footer {
    background-color: $yellow-light;
    margin-top: 2rem;
    padding: 4rem 0 0 0;

    .form-inline {
      width: 100%;
      .form-control {
        background-color: $white;
        padding: 1.6rem 1rem;
        border: none;
      }

      .subscribe-btn {
        @include custom-button($primary, $white, 12pt, 600, .8rem 1rem, 1px solid $primary, 0, $secondary, $white, 1px solid $secondary);
        margin-left: -2px;
      }
    }

    .second-column {
      .links-footer {
        .link-color {
          padding: 1rem 0;
          border-bottom: 1px solid $gray-300;

        }
        h4 {
          @include font($primary, 12pt, 700);
        }
        ul {
          padding-inline-start: 2px;
          li {
            list-style: none;
            padding-bottom: 1rem;
            a {
              @include link($dark, 11pt, 500, none, $gray-200);
            }
          }
        }
      }
    }

    .copyright {
      /*margin-top: 2rem;
      padding: 1.5rem 0;*/
      margin-bottom: 1rem;

      .all-right-text {
        @include font($primary, 12pt, 600);
      }

      .social-media-link {
        a {
          @include link($primary, 12pt, 500, none, $secondary);
          padding-left: 1rem;
        }
      }
    }
  }

  .cat-btn {
    padding: .7rem 0 0 0;
  }

  // contact-page

  .contact-page {
    .contact-us {
      .white-card {
        @include custom-card($white, 1rem, 1rem 0, $box-shadow);

        .white-card-title {
          @include font($dark, 15pt, 600);
        }

        .address-content {
          h1 {
            color: $gray-200;
            padding-right: 1rem;
          }

          h6 {
            padding-top: 8px;
            @include font($dark, 11pt, 600);

          }
        }
      }
    }

    .send-message {
      .white-card {
        @include custom-card($white, 2rem 1rem, 2rem 0, $box-shadow);

        .form-control {
          border: 1px solid $gray-100;
          @include font($gray-200, 11pt, 500);
        }
      }
    }
  }
  // contact-page end

  //.....................................................................................

  // Product Details Page

  .product-details {

    .product-details-area {

      .prdct-title {
        color: $dark;
      }

      .prdct-price {
        h3 {
          @include font($primary, 22pt, 500);
          margin: 2rem 0;
        }

        p {
          @include font($gray-300, 12pt, 600);
        }
      }

      .w-sm-50 {
        width: 40%;
        display: table-cell;
      }

      .w-sm-25 {
        width: 30%;
        display: table-cell;
      }

      .w-sm-40 {
        width: 45%;
        display: table-cell;
      }

      .prdct-desc {
        p {
          @include font($gray-400, 11pt, 500);

          a {
            @include link($primary, 12pt, 600, underline, $secondary);
          }
        }
      }

      .prdct-para {
        div {
          label {
            display: block;
          }
          .form-control {
            display: inline-block;
            width: fit-content;
          }
        }
      }

      .prdct-qty {
        div {

          label {
            display: inline-block;
          }
          .form-control {
            padding: 1.2rem;
            display: inline-block;
            width: 100px;
            margin-bottom: -1px;
          }
          button {
            @include custom-button($white, $primary, 15pt, 600, .35rem 1rem, none, 0, $primary, $white, none);
          }
        }
      }

      .add-cart {
        .add-to-cart-btn {
          @include custom-button($secondary, $white, 13pt, 600, .7rem 2rem, 1px solid $secondary, 0, $primary, $white, 1px solid $primary);
          width: 100%;
          display: block;
          text-align: center;
        }

        .add-fav {
          @include custom-button(transparent, $primary, 15pt, 600, 1.5rem 2rem, none, 0, none, $secondary, none);
          text-align: center;
          width: 100%;
          display: block;
        }
      }

      .share-social {
        span {
          a {
            margin-left: .3rem;
            @include link($gray-200, 13pt, 500, none, $secondary);
            padding: .5rem;
            border-radius: 0;
            border: 1px solid $gray-200;

            &:hover {
              border: 1px solid $secondary;
            }
          }
        }
      }

      .white-card {
        @include custom-card($white, 1rem, 2rem 0, $box-shadow);

        .nav-pills {
          .nav-link {
            font-weight: 600;
            color: $gray-200;
          }

          .nav-link.active {
            color: $primary;
          }
        }

        .tab-content {
          .tab-pane {
            p {
              color: $gray-300;
              text-align: justify;
            }
          }

        }

      }

      // Product-1 Section
      .product-1 {
        .product-card {
          .product-img {
            max-height: 550px;
            width: 100%;
            .product-image-image {
              width: 100%;
              height: 100%;
            }
          }

          .p-des {
            color: $gray-200;
          }
        }
      }

      // Carousel
      .carousel-inner {
        min-height: 100%;

        .img-fluid {
          width: 100%;
        }
      }

      #myCarousel {
        .list-inline {
          white-space: nowrap;
          overflow-x: auto;
        }
      }

      #myCarousel {
        .carousel-indicators {
          position: static;
          left: initial;
          width: initial;
          margin-left: initial;
          margin-top: 2rem;
        }
      }

      #myCarousel {
        .carousel-indicators > li {
          width: 250px;
          height: initial;
          text-indent: initial;
          border: 1px solid $gray-300;
        }
      }

      #myCarousel {
        .carousel-indicators > li.active {
          border: 1px solid $secondary;
        }
      }

      #myCarousel {
        .carousel-indicators > li.active img {
          opacity: 0.7;
        }
      }

    }

  }
  // Product Details End

  //...........................................................................................

  // About Page

  .about-page {
    background: linear-gradient(to bottom, rgb(255 255 255 / 89%), #fff), url(/img/about-us.png) no-repeat;
    .about-us {
      p {
        color: $gray-300;
      }

    }

  }
  // contact-page end

  //.....................................................................................

  // Shop-page

  .shop-page {

    .shop {
      .white-card {
        @include custom-card($white, 1rem, 0, $box-shadow);

        .card {
          border: none;
          border-bottom: 1px solid $gray-200;

          .confirm-link {
            @include custom-button($white, $primary, 14pt, 600, .5rem 0, none, 0, white, $primary, none)
          }

          .card-body {
            label {
              display: block;
            }
            .nav-pills {
              .nav-link {
                font-weight: 600;
                color: $gray-200;
              }

              .nav-link.active {
                color: $primary;
              }
            }

            .tab-content {
              .tab-pane {
                p {
                  color: $gray-300;
                  text-align: justify;
                }
              }

            }
          }
        }
      }

      .normal-card {
        @include custom-card(Transparent, 1rem, 4rem 0, none);

        .header-title {
          h4 {
            @include font($dark, 18pt, 600);
          }
        }

        .featured-item {
          margin: 1rem 0;
          width: 100%;
          .media {
            img {
              width: 100px;
            }

            .media-body {
              .discount-tag {
                @include custom-card($secondary, .3rem 1rem, .4rem 0, none);
                color: $white;
                font-weight: 600;
              }

              a {
                @include link($dark, 13pt, 600, none, $secondary);

                h5 {
                  @include font($dark, 13pt, 600);
                  color: unset !important;
                }
              }

              h6 {
                @include font($primary, 12pt, 500);
              }
            }
          }
        }
      }
    }

    .right-side {
      .controls-top {
        label {
          padding: .5rem .5rem 0 0;
        }

        .form-control {
          border: none;
          font-weight: 500;
        }

        .btn {
          padding: .4rem 0.5rem;
        }
      }
    }

    // Card Section
    .card-section {
      .gray-card {
        @include custom-card($bg-gray, 1.5rem 2rem, 1rem 0, none);

        h1 {
          @include font($dark, 12pt, 700);
          text-transform: uppercase;

          span {
            font-weight: 700;
            color: $secondary;
          }
        }

        p {
          @include font($gray-400, 9pt, 500);
          @include limit(2);
        }

        .border-less-btn {
          @include custom-button(transparent, $primary, 11pt, 600, 1rem 0, none, 0, transparent, $secondary, none);
          text-transform: uppercase;

        }
      }
    }

  }
  // Shop-page End

  #owl-1, #owl-tes, #owl-11, #owl-10, #owl-9 {
    .owl-nav button.owl-next {
      right: -9px;
    }
    .owl-nav button.owl-prev {
      left: -9px;
    }
  }
  .success-stories #owl-1 .owl-nav button.owl-next {
    transform: translate(0, -50%);
    right: -13px;
    top: calc(50% - 63px) !important;
  }
  .success-stories #owl-1 .owl-nav button.owl-prev {
    transform: translate(0, -50%);
    left: -12px;
    top: calc(50% - 63px) !important;
  }
  //...................................................................................

}

// End

@media (min-width: 992px) and (max-width: 1600px) {
  .home-page {

    // Slider Section
    .main-slider {
      .carousel-inner {

        .slider-img {
          height: fit-content;
        }
      }

      .carousel-control-next, .carousel-control-prev {
        color: $primary;
        font-size: 30pt;
      }
    } 
  }
  #donate-now-footer{
    background: #69BEB1;
    bottom: 0;
    box-shadow: 5px 0 15px rgba(0,0,0,.2);
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 10px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 99999;
    .btn {
      background: #EED779;
      border-radius: 25px;
      font-size: 12px;
      font-family: "american" !important;
      padding: .375rem .75rem;
    }
    p {
      margin: 0;
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .success-stories #owl-1 .owl-nav button.owl-prev {
    transform: translate(0, -50%);
    left: -20px;
    top: calc(50% - 65px) !important;
  }
  .success-stories #owl-1 .owl-nav button.owl-next {
    transform: translate(0, -50%);
    right: -20px;
    top: calc(50% - 65px) !important;
  }
  .success-stories .item .content h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .footer{
    padding-bottom: 6rem;
    #about {
      list-style: none;
      padding: inherit;
    }
    #our{
      list-style: none;
      padding: inherit;
    }
    #gallery{
      list-style: none;
      padding: 0 19px;
    }
    #media{
      list-style: none;
      padding: inherit;
    }
    #reach{
      list-style: none;
      padding: inherit;
    }
  }
  #login-signup-modal {
    z-index: 9999999999 !important;
  }
}

// For Ipad

@media only screen and (max-width: 768px) {
  .home-page{
    #homeslider ul.image_slider_ul {
      width: 807%;
    }
    #homeslider ul.image_slider_ul li .bgimage {
      width: 100%;
      height: 100%;
      position: relative;
      background-position: 15% 0 !important;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        opacity: .7;
        background-color: $dark;
        width: 100%;
        height: 100%;
      }
    }
    #homeslider ul.image_slider_ul li .bgimage .carousel-caption .row{
      justify-content: start !important;
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle {
      margin-bottom: 9rem;
      padding: 0 !important;
      margin-right: 9rem;
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle .main-title {
      margin-bottom: 1rem;
      color: #69BEB1;
      font-weight: 700;
      font-size: 1.9rem;
    }
    .success-stories{
      .owl-carousel {
        .owl-stage-outer{
          height: 32rem;
          .owl-stage{
            .item {
              .overlay-effect{
                /*height: 43%;*/
              }
            }
          }
        }
      }
      #owl-1{
        .owl-nav{
          .owl-prev{
            top: calc(50% - 63px) !important;
          }
          .owl-next{
            top: calc(50% - 63px) !important;
          }
        }
      }
    }
    .what-is-diffrent {
      background-color: #69BEB1;
      color: #ffffff;
      padding: 3rem;
    }
    #ourvision {
      padding-bottom: 3rem !important;
      #vissionsection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        p{
          margin-bottom: 5.5rem !important;
        }
      }
      #missionsection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
      }
      #valuessection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
      }
    }
    .our-partners{
      .owl-carousel .owl-height .item img {
        height: 216px;
        width: 250px;
        display: block;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        border: 9px solid lightgray;
      }
      .newssection {
        background: #69beb1;
        margin: 20px auto;
        overflow: hidden;
        border-radius: 4px;
        padding: 1px;
        -webkit-user-select: none;
        .text1 {
          float: left;
          color: #fff;
          padding: 9px;
          position: relative;
          top: 1%;
          box-shadow: inset 0 -15px 30px rgba(0, 0, 0, 0.4);
          -webkit-font-smoothing: antialiased;
          -webkit-user-select: none;
          cursor: pointer;
          box-shadow: none !important;
          width: 100%;
        }
      }
    }
    .our-testimonials {
      .owl-slider {
        .item {
          margin-bottom: 1rem;
          .white-card {
            @include custom-card($white, 1rem, 0, none);
            border: none;
            transform: translateY(4rem);
            transition: .5s all ease-in-out;

            .media {
              .quoat {
                color: $danger-light;
                font-size: 30px;
                position: absolute;
                right: 1rem;
                top: 1rem;
                display: block;
                z-index: 9999;
              }

              .img-tes {
                width: 64px;
                height: 72px;
                border: 5px solid $white;
                box-shadow: $box-shadow;
                border-radius: 50%;
              }
            }

            .details {
              margin-top: 1rem;
              padding-top: 1rem;
              border-top: 1px solid $gray-100;
              opacity: 0;
              transform: translateY(-1rem);
              transition: 1s all ease-in-out;
            }
          }
        }

        // Owl  Carousel
        .owl-carousel.owl-drag .owl-item.center .item .details {
          opacity: 1;
          transition: 1s all ease-in-out;
          transform: translateY(0);
        }

        .owl-carousel.owl-drag .owl-item.center .item .white-card {
          border: 1px dashed $gray-200;
          transform: translateY(0);
          transition: 1s all ease-in-out;
        }

        //owl-nav

        .owl-nav {
          .owl-next {
            color: $dark;
            background-color: $white;
            padding: 1rem;
            border-radius: 50%;
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }
  .footer{
      background-color: #fbd789;
      margin-top: 2rem;
      padding: 4rem 0 0 0;
    #about {
      list-style: none;
      padding: inherit;
    }
    #our{
      list-style: none;
      padding: inherit;
    }
    #gallery{
      list-style: none;
      padding: 0 19px;
    }
    #media{
      list-style: none;
      padding: inherit;
    }
    #reach{
      list-style: none;
      padding: inherit;
    }
  }
  #donate-now-footer {
    background: #69BEB1;
    bottom: 0;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 99999;
    .btn {
      background: #EED779;
      border-radius: 25px;
      font-size: 12px;
      font-family: "american" !important;
      padding: 0.375rem 0.75rem;
    }
  }
}

// For Ipad Pro

@media only screen and (max-width: 1199px) {
  .home-page{
    #homeslider ul.image_slider_ul {
      width: 807%;
    }
    #homeslider ul.image_slider_ul li .bgimage {
      width: 100%;
      height: 100%;
      position: relative;
      background-position: 15% 0 !important;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        opacity: .7;
        background-color: $dark;
        width: 100%;
        height: 100%;
      }
    }
    #homeslider ul.image_slider_ul li .bgimage .carousel-caption .row{
      justify-content: start !important;
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle {
      margin-bottom: 9rem;
      padding: 0 !important;
      margin-right: 9rem;
    }

    #newslidertext .bgimage .carousel-caption #slidersubtitle .main-title {
      margin-bottom: 1rem;
      color: #69BEB1;
      font-weight: 700;
      font-size: 1.9rem;
    }
    .success-stories{
      .owl-carousel {
        .owl-stage-outer{
          height: 32rem;
          .owl-stage{
            .item {
              .overlay-effect{
                /*height: 43%;*/
              }
            }
          }
        }
      }
    }
    .what-is-diffrent {
      background-color: #69BEB1;
      color: #ffffff;
      padding: 3rem;
    }
    #ourvision {
      padding-bottom: 3rem !important;
      #vissionsection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        padding: 1.5rem;
        border-radius: 1rem;
        color: #ffffff;
        p{
          margin-bottom: 5.5rem !important;
        }
      }
      #missionsection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        padding: 1.5rem;
        border-radius: 1rem;
        color: #ffffff;
      }
      #valuessection{
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        padding: 1.5rem;
        border-radius: 1rem;
        color: #ffffff;
      }
    }
    .our-partners{
      .owl-carousel .owl-height .item img {
        height: 216px;
        width: 250px;
        display: block;
        margin-right: auto;
        margin-left: auto;
        border-radius: 50%;
        border: 9px solid lightgray;
      }
      .newssection {
        background: #69beb1;
        margin: 20px auto;
        overflow: hidden;
        border-radius: 4px;
        padding: 1px;
        -webkit-user-select: none;
        .text1 {
          float: left;
          color: #fff;
          padding: 9px;
          position: relative;
          top: 1%;
          box-shadow: inset 0 -15px 30px rgba(0, 0, 0, 0.4);
          -webkit-font-smoothing: antialiased;
          -webkit-user-select: none;
          cursor: pointer;
          box-shadow: none !important;
          width: 100%;
        }
      }
    }
    .our-testimonials {
      .owl-slider {
        .item {
          margin-bottom: 1rem;
          .white-card {
            @include custom-card($white, 1rem, 0, none);
            border: none;
            transform: translateY(4rem);
            transition: .5s all ease-in-out;

            .media {
              .quoat {
                color: $danger-light;
                font-size: 30px;
                position: absolute;
                right: 1rem;
                top: 1rem;
                display: block;
                z-index: 9999;
              }

              .img-tes {
                width: 64px;
                height: 72px;
                border: 5px solid $white;
                box-shadow: $box-shadow;
                border-radius: 50%;
              }
            }

            .details {
              margin-top: 1rem;
              padding-top: 1rem;
              border-top: 1px solid $gray-100;
              opacity: 0;
              transform: translateY(-1rem);
              transition: 1s all ease-in-out;
            }
          }
        }

        // Owl  Carousel
        .owl-carousel.owl-drag .owl-item.center .item .details {
          opacity: 1;
          transition: 1s all ease-in-out;
          transform: translateY(0);
        }

        .owl-carousel.owl-drag .owl-item.center .item .white-card {
          border: 1px dashed $gray-200;
          transform: translateY(0);
          transition: 1s all ease-in-out;
        }

        //owl-nav

        .owl-nav {
          .owl-next {
            color: $dark;
            background-color: $white;
            padding: 1rem;
            border-radius: 50%;
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }
  .footer{
      background-color: #fbd789;
      margin-top: 2rem;
      padding: 4rem 0 0 0;
      padding-bottom: 6rem;
    #about {
      list-style: none;
      padding: inherit;
    }
    #our{
      list-style: none;
      padding: inherit;
    }
    #gallery{
      list-style: none;
      padding: unset;
    }
    #media{
      list-style: none;
      padding: inherit;
    }
    #reach{
      list-style: none;
      padding: inherit;
    }
  }
  
  #donate-now-footer {
    background: #69BEB1;
    bottom: 0;
    box-shadow: 5px 0 15px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 99999;
    .btn {
      background: #EED779;
      border-radius: 25px;
      font-size: 12px;
      font-family: "american" !important;
      padding: 0.375rem 0.75rem;
    }
  }
  
}
