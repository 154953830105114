//Custom Card
@mixin custom-card($clr, $pdd, $mrgn, $shadow) {
  background-color: $clr;
  padding: $pdd;
  margin: $mrgn;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Fonts
@mixin font($clr, $size, $weight) {
  font-family: $font-family;
  font-size: $size;
  font-weight: $weight;
  color: $clr;
}

@mixin link($clr, $size, $weight, $deco, $h-clr) {
  color: $clr;
  font-size: $size;
  font-weight: $weight;
  transition: $hover;

  &:hover {
    text-decoration: $deco;
    color: $h-clr;
    transition: $hover;
  }
}

@mixin custom-button($bg-clr, $clr, $f-size, $f-wgt, $pdd, $bdr, $bdr-r, $h-bg, $h-clr, $h-bdr) {
  background-color: $bg-clr;
  color: $clr;
  font-size: $f-size;
  font-weight: $f-wgt;
  padding: $pdd;
  border: $bdr;
  border-radius: $bdr-r;
  text-decoration: none;
  transition: $hover;

  &:hover {
    background-color: $h-bg;
    color: $h-clr;
    border: $h-bdr;
    text-decoration: none;
    transition: $hover;

  }

}

@mixin transition($s, $t) {
  -webkit-transition: $s $t;
  -moz-transition: $s $t;
  -ms-transition: $s $t;
  -o-transition: $s $t;
  transition: $s $t;
}

@mixin limit($cl) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $cl; /* number of lines to show */
  -webkit-box-orient: vertical;
}
