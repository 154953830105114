.footer {
  .footer-top-bar {

  }

  .list {
    .m-button {
      padding: .6rem 0;
    }
  }
}