.top-menu {
  .navbar {
    background-color: #0000004f;
    z-index: 99999;

    .collapse {
      .navbar-nav {
        .nav-item {
          margin-left: 1rem;

          .nav-link {
            @include font($white, 11pt, 400);
            font-family: "american";
          }
        }
      }
    }
    .navbar-brand {
      margin-right: 3rem;
    }
  }
}