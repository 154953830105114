// Main
:root {
  --animate-delay: 0.5s;
}

html,body {
  font-family: $font-family;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="submit"]:focus, select:focus {
  outline: none;
  box-shadow: none;
}

.title {
  font-family: 'bradley';
  font-weight: 7  00;
}

p, span, a, li {
  font-family: 'american';
  font-weight: 400;
  letter-spacing: 1px;
}

// Common-styles
.header-title {
  @include font($dark, $header-font-size, $header-font-weight);
  text-transform: uppercase;
}

.form-control {
  padding: 1.5rem 1rem;
  border-color: $success-light;
  color: $gray-200;
}

.hero {
  background-size: 100% !important;
  height: auto;

  .hero-img {
    width: 100%;
    height: auto;
    margin-top: -5.555rem;
  }
}

.label-label {
  @include font($success-light, 12px, 500);
  background-color: $white;
  padding: .2rem .5rem;
  margin-bottom: -20px;
  margin-left: 1rem;
  position: absolute;
  top: -10px;
  display: inline-block;
  z-index: 999;
}

.sub-title {
  @include font($gray-300, $sub-header-font-size, $sub-header-font-weight);
  text-transform: uppercase;
  background-color: $white;
  z-index: 2;
  position: relative;
  display: inline-block;
}

.sub-title-line-through {
  background-color: $gray-100;
  width: 100%;
  height: 1px;
  margin-top: -18px;
  z-index: 1;
  position: relative;
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

// Owl Carousel Nav Button
#owl-1, #owl-tes, #owl-11, #owl-10, #owl-9 {
  .owl-nav button {
    position: absolute !important;
    /* top: 50%; */
    top: calc(50% - 6px);
    height: 45px;
    width: 45px;
    transform: translateY(-50%);
    font-size: 25px !important;
    opacity: 1;
    outline: 0 !important;
    color: #343a40 !important;
    background: white !important;
    color: #FFF;
    text-decoration: none;
    margin: 0 !important;
    border-radius: 100% !important;
    box-shadow: 1px 1px 6px -4px #333;
    border: 1px solid #f5f5f5 !important;
    padding: 0 !important;
    line-height: 0 !important;
    text-align: center;
  }
  .owl-nav button.owl-next {
    transform: translate(0, -50%);
    right: -20px;
  }

  .owl-nav button.owl-prev {
    transform: translate(0, -50%);
    left: -20px;
  }
  .owl-theme .owl-nav [class*=owl-]:hover {
    background: #FFFFFF !important;
    color: #FFF;
    text-decoration: none;

  }
}

.btn-white {
  @include custom-button($white, $dark, 11pt, 600, .7rem 2.2rem, 1px solid $white, 50px, $primary, $white, 1px solid $primary)
}

.btn-dark {
  @include custom-button($dark, $white, 11pt, 600, .7rem 2.2rem, 1px solid $dark, 50px, $white, $dark, 1px solid $white)
}

.btn-white-round {
  @include custom-button($white, $dark, 11pt, 600, .7rem .9rem, 1px solid $white, 50%, $primary, $white, 1px solid $primary)
}

.btn-green {
  @include custom-button($success-light, $white, 11pt, 600, .5rem 2rem, 1px solid $success-light, 50px, $white, $dark, 1px solid $white)
}

.btn-yellow {
  @include custom-button(#EED779, $dark, 11pt, 600, .5rem 2rem, 1px solid #EED779, 50px, $success-light, $white, 1px solid $success-light)
}

.btn-transparent {
  @include custom-button(Transparent, $white, 11pt, 600, .5rem 2rem, none, 50px, Transparent, $danger-light, none)
}

.btn-red {
  @include custom-button($danger-light, $white, 11pt, 600, .5rem 2rem, 1px solid $danger-light, 50px, $white, $dark, 1px solid $white);
  &:hover {
    color: $dark !important;
  }
}

.product-title {
  @include font($gray-200, 12pt, 500);
}

.product-price {
  @include font($primary, 13pt, 600);
}

.line-through-center {
  width: 5rem;
  height: 3px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  background-color: $danger-light;
}

.line-through-left {
  width: 5rem;
  height: 3px;
  display: block;
  background-color: $danger-light;
}

.line-through-white {
  width: 5rem;
  height: 3px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  background-color: $white;
}

.line-through-white-left {
  width: 5rem;
  height: 3px;
  display: block;
  background-color: $white;
}

.product-modal {
  .modal {
    .modal-header {
      border-bottom: 1px solid $bg-gray;
    }

    .modal-body {
      .carousel {
        .carousel-item {
          max-height: 250px;

          img {
            height: 250px;
            margin-right: auto;
            margin-left: auto;
            display: block;
          }
        }
      }
    }

    .modal-footer {
      border-top: 1px solid $bg-gray;
    }

    .carousel-control-prev-icon , .carousel-control-next-icon {
      div {
        color: $dark;
        background-color: $white;
        box-shadow: $box-shadow;
        padding: 1rem;
        width: fit-content;
        height: fit-content;
        border-radius: 50px;
      }
    }
  }
}


.breadcrumb {
  margin-top: 1rem;

  li {
    @include font($text-dark, 14px, 400);
  }
}

// Colors And Bg- Colors
// colors
.color-primary { color: $primary !important;}
.color-secondary { color: $secondary !important;}
.color-success { color: $success !important;}
.color-info { color: $info !important;}
.color-warning { color: $warning !important;}
.color-gray-300 { color: $gray-300 !important;}

// BG - colors classess
.bg-color-primary { background-color: $primary !important;}
.bg-color-secondary { background-color: $secondary !important;}
.bg-color-success { background-color: $success !important;}
.bg-color-info { background-color: $info !important;}
.bg-color-warning { background-color: $warning !important;}
.bg-color-gray-300 { background-color: $gray-300 !important;}

// Card block Bg colors
.card-1-bg{  background-color: $card-1-bg;}
.card-2-bg{  background-color: $card-2-bg;}
.card-3-bg{  background-color: $card-3-bg;}

.text-small { font-size: 10pt;}


.trending{
  padding-top: 1rem;
}

