@import 'common';
@import 'footer';
@import 'nav-bar';

@media (min-width: 1200px) {
  .home-page {
    .hero {
      /*background: url('../img/new/hero.png') no-repeat;
      background-size: 100%;
      padding-bottom: 5rem;*/
      padding-bottom: unset;
    }

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
        text-align: left !important;
      }

    }

    .success-stories {
      background-color: $white;

      .details-block {
        h6 {
          @include font($dark, 14pt, 500);
        }

        .years {
          @include font($gray-200, 14px, 400);
        }

        .description {
          @include font($dark, 11pt, 400);
        }
      }
    }

    .gray-bg {
      background-color: $bg-gray;
      background-size: 50%;
      margin: 5rem 0 0 0;
      padding: 5rem;

      .green-bg {
        background-color: #4E7FC1;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }

      .blue-bg {
        background-color: $yellow-light;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }

      .red-bg {
        background-color: #D66359;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }

    }

    .home-about-us {
      .img-round {
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;

        .water-drop {
          position: absolute;
        }
      }
    }

    .what-is-diffrent {
      background-color: $success-light;
      color: $white;
      padding: 3rem;
      ul{
        margin: unset;
        padding: unset;
        list-style: none;
      }

      /*#zoom {
        transform: scale(1);
        transition: .5s all ease-in-out;
      }
      #zoom:hover {
        transform: scale(1.3);
        transition: .5s all ease-in-out;
      }*/
      .zoom-area{
			  width: 500px;
			  margin: 50px auto;
			  position: relative;
        cursor: none;
        .magnify{
			    width: 175px;
          height: 175px;
          position: absolute;
          border-radius: 100%;
          transform: scale(1.3);
          /* for box shadow for glass effect */
          box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.85), 
          0 0 7px 7px rgba(0, 0, 0, 0.25), 
          inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
          
          /*for hide the glass by default*/
          display: none;
		    }
        .leansimages{
			   display: block;
		    }
		  }
    }

    .some-header {
      padding: 5rem 0;

      .color-gray-200 {
        color: $gray-300;
      }

      .freame {
        position: relative;
        .dot-green {
          position: absolute;
          background-color: $success-light;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          z-index: -1;
          left: -.5rem;
          top: -.3rem;

          .students {
            @include font($gray-200, 11pt, 400);
          }
        }

        .dot-orange {
          position: absolute;
          background-color: $danger-light;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          z-index: -1;
          left: -.5rem;
          top: -.3rem;

          .students {
            @include font($gray-200, 11pt, 400);
          }
        }
      }

      .rounded-border {
        border: 1px solid $gray-100;
        padding: 2rem;
        border-radius: 1rem;
      }
    }

    .our-testimonials {
      .owl-slider {
        .item {
          margin-bottom: 1rem;
          .white-card {
            @include custom-card($white, 1rem, 0, none);
            border: none;
            transform: translateY(4rem);
            transition: .5s all ease-in-out;

            .media {
              .quoat {
                color: $danger-light;
                font-size: 30px;
                position: absolute;
                right: 1rem;
                top: 1rem;
                display: block;
                z-index: 9999;
              }

              .img-tes {
                width: 120px;
                height: 120px;
                border: 5px solid $white;
                box-shadow: $box-shadow;
                border-radius: 50%;
              }
            }

            .details {
              margin-top: 1rem;
              padding-top: 1rem;
              border-top: 1px solid $gray-100;
              opacity: 0;
              transform: translateY(-1rem);
              transition: 1s all ease-in-out;
            }
          }
        }

        // Owl  Carousel
        .owl-carousel.owl-drag .owl-item.center .item .details {
          opacity: 1;
          transition: 1s all ease-in-out;
          transform: translateY(0);
        }

        .owl-carousel.owl-drag .owl-item.center .item .white-card {
          border: 1px dashed $gray-200;
          transform: translateY(0);
          transition: 1s all ease-in-out;
        }

        //owl-nav

        .owl-nav {
          .owl-next {
            color: $dark;
            background-color: $white;
            padding: 1rem;
            border-radius: 50%;
            box-shadow: $box-shadow;
          }
        }
      }
    }
  }

  .our-partners {
    .owl-carousel {
      .owl-height {
        height:250px;

        .item {
          margin-left: 0;
          margin-right: 0;
          img {
            height: 250px;
            width: 100%;
            border-radius: 50%;
            border: 9px solid lightgray;
          }


        }
      }

    }
    .newssection{
      background: #69beb1;
      margin: 20px auto;
      overflow: hidden;
      border-radius: 4px;
      padding: 1px;
      -webkit-user-select: none;
      span {
        float: left;
        color: #fff;
        padding: 9px;
        position: relative;
        top: 1%;
        box-shadow: inset 0 -15px 30px rgba(0,0,0,0.4);
        font: 16px 'Raleway', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-user-select: none;
        cursor: pointer;
      }
      .text1{
        box-shadow:none !important;
        width: 100%;
      }
    }
  }

  .footer hr{
    margin-bottom: revert;
  }
  #donate-now-footer{
    background: #69BEB1;
    bottom: 0;
    box-shadow: 5px 0 15px rgba(0,0,0,.2);
    color: #fff;
    font-size: 12px;
    left: 0;
    padding: 10px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 99999;
    .btn {
      background: #EED779;
      border-radius: 25px;
      font-size: 12px;
      font-family: "american" !important;
      padding: .375rem .75rem;
    }
    p {
      margin: 0;
    }
  }
  
  .footer {
    background-color: $yellow-light;
    margin-top: 5rem;
    padding-bottom: 3rem;

    .footer-top-bar {
      padding: 2rem 0;
    }

    .list {

      ul {
        padding-inline-start: 0;
        margin-top: 2rem;

        li {
          list-style: none;
          padding-bottom: .8rem;
        }
      }
    }

    hr {
      background-color: $dark;
    }

    .copyright {
      .social-media-link {
        display: flex;
        flex-direction: row;
        span {
          list-style: none;
          padding: .6rem;
          background-color: $white;
          color: $dark;
          margin-left: 1rem;
          width: 55px;
          border-radius: 50%;
          font-size: 25px;
        }
      }

      .copy {
        display: block;
        /*padding: 1.5rem 0;*/
        padding: unset;
        @include font($dark, 11pt, 500);
        font-family: "american";
      }
    }

  }

  // About Us

  .about-us {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }

    .gray-bg {
      background-color: $bg-gray;
      background-size: 50%;
      margin: 5rem 0 0 0;
      padding: 5rem;

      .green-bg {
        background-color: #69BEB1;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }

      .blue-bg {
        background-color: #4E7FC1;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }

      .red-bg {
        background-color: #D66359;
        padding: 1.5rem;
        border-radius: 1rem;
        color: $white;
      }
    }

  }

  // employ

  .employ {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }
  }

  // employ

  .students {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }
  }

  // help-raise

  .help-raise {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }
  }

  // spread

  .spread {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }
  }

  // spread

  .careers {

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }
  }

  // fees

  .fees {
    .hero {
      background: url('../img/new/fees.png') no-repeat;
      background-size: 100%;
      padding-bottom: 11rem;
    }

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }

    .fees-box {
      .card-custom {
        border-radius: 1.5rem;
        border: 1px solid $gray-100;

        .card-header {
          border-radius: 1.5rem 1.5rem 0 0;
          background-color: $info-light;
          color: $white;
        }

      }
    }
  }

  // faqs

  .faq {
    .hero {
      background: url('../img/new/faq.png') no-repeat;
      background-size: 100%;
      padding-bottom: 5rem;
    }

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }

    .faqs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            border-color: #fff;
            @include font($gray-300, 13pt, 500);
          }

          .nav-link.active {
            border-color: #fff;
            @include font($dark, 13pt, 600);
          }
        }
      }

      .tab-content {
        .card-header {
          background-color: $info-light;

          .btn-link {
            @include font($white, 13pt, 600);
            font-family: "american" !important;
          }
        }
      }

    }
  }

  // top-donners

  .top-donners {
    .hero {
      background: url('../img/new/donners.png') no-repeat;
      background-size: 100%;
      padding-bottom: 11rem;
    }

    .main-carousel {
      .main-title {
        @include font($white, 35pt, 500);
      }

      .main-sub {
        @include font($white, 13pt, 500);
      }

    }

    .fees-box {
      .card-custom {
        border-radius: 1.5rem;
        border: 1px solid $gray-100;

        .card-header {
          border-radius: 1.5rem 1.5rem 0 0;
          background-color: $info-light;
          color: $white;
        }

      }
    }
  }

}

.mt-9 {
  margin-top: 5rem !important;
}

//Back to top button start

#backtotop {
  display: inline-block;
  background-color: #69BEB1;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  /*bottom: 30px;
  right: 30px;*/
  bottom: 82px;
  right: 20px;
  transition: background-color .3s,
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  .fas{
    font-size: 2em;
    line-height: 46px;
    color: #fff;
  }
}

/*#backtotop::after {
  content: "\2191";
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 43px;
  color: #fff;
}*/

#backtotop:hover {
  cursor: pointer;
  background-color: #333;
}

#backtotop:active {
  background-color: #555;
}

#backtotop.show {
  opacity: 1;
  visibility: visible;
  box-shadow: 0px 0px 9px 0px #888888;
}

// Board member

#boardmembersection {
  .green-bg {
    background-color: #4E7FC1;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #boardmemberheader .card {
    margin-left: 18%;
    margin-right: 18%;
    width: auto;
  }
  #boardmember {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      background: #D66359;
      color: white;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
      .card-title {
        padding: 1rem;
        background: #A02F22;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        color: white;
      }
      button {
        float: right;
        color: white;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
      button:focus {
        outline: none !important;
      }
    }
  }
}

// Audited report

#auditedsection .white-bg {
  background-color: white;
  color: black;
  border: none;
}

#auditedsection #auditedheader .card {
  margin-left: 18%;
  margin-right: 18%;
  width: auto;
}

#auditedsection #auditedpart {
  border: 1px solid #b9c0cf00;
  margin-left: 9%;
  margin-right: 9%;
}

#auditedsection #auditedpart .card-body {
  padding: 1rem;
  background: #CBA950;
  margin-bottom: unset;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

#auditedsection #boardmember .card-body {
  padding: 1rem;
}

#auditedsection #auditedpart .cardhover {
  position: relative;
  background-color: #2f2f2f;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

#auditedsection #auditedpart .card-img-top {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

#auditedsection #auditedpart .middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
}

#auditedsection #auditedpart .cardhover:hover .card-img-top {
  opacity: 0.3;
}

#auditedsection #auditedpart .cardhover:hover .middle {
  opacity: 1;
}

#auditedsection #auditedpart .downloadhover {
  background-color: #f5f5f5;
  color: white;
  font-size: 16px;
  padding: 11px 10px;
  border-radius: 54px;
  width: 50px;
  height: 48px;
  margin-right: 16px;
}

#auditedsection #auditedpart .eyeiconhover {
  background-color: #f5f5f5;
  color: white;
  font-size: 16px;
  padding: 11px 10px;
  border-radius: 54px;
  width: 50px;
  height: 48px;
  margin-right: 16px;
}

// Annual calender

#annualcalender #coursesubmenu {
  background: #ffffff;
  font-size: 14px;
  border: 1px solid #dfdfdf;
  margin-bottom: unset;
  /* margin-top: 5px; */
  padding-top: 9px;
  border-radius: unset;
  margin: unset;
  border-top: none;
}

#annualcalender #coursesubmenu li {
  border-bottom: unset;
}

#annualcalender #coursesubmenu #submenu {
  text-decoration: none;
  color: black;
  padding-bottom: 0.5rem;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
  width: 100%;
  min-width: 15.8rem;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

#annualcalender #coursesubmenu #submenu:hover {
  background-color: #4e7fc1;
  color: #ffffff;
}

#auditedsection .list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #4E7FC1;
  border-color: #4E7FC1;
  border-radius: unset;
}

#maincourse::after {
  display: none;
}

#annualcalender .pb-55 {
  padding-bottom: 5rem;
}

// Home image slider

#homeslider {
  position: relative;
  /* margin: 20px auto 0 auto; */
  width: 100%;
  height: 533px;
  overflow: hidden;
  margin-top: -6rem;
  overflow: hidden;
}

#homeslider {
  p {
    text-align: left !important;
  }
}

#homeslider ul.image_slider_ul {
  position: relative;
  margin: 0;
  padding: 0;
  width: 400%;
  height: 100%;
  list-style: none;
  margin-left: unset !important;
}

#homeslider ul.image_slider_ul li {
  position: relative;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 25%;
  height: 100%;
}

#homeslider ul.image_slider_ul li .bgimage {
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color: #333; */
  background-position: 100% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  /* -webkit-filter: blur(2px); */
}

/*  Indicators*/

#homeslider .indicator_con {
  width: 100%;
  height: auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  bottom: 0;
}

#homeslider .indicator_con ul.indicator {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
}

#homeslider .indicator_con ul.indicator li {
  position: relative;
  list-style: none;
  width: 20px;
  height: 4px;
  background-color: #fff;
  /* border: 1px solid #fff; */
  display: inline-block;
  margin: 0 3px;
  border-radius: 1.5px;
  transition: 800ms ease-in-out;
}

#homeslider .indicator_con ul.indicator li.active {
  background-color: #F2B206;
}

/*  Buttons*/

#homeslider .control_prev, button.control_next {
  position: absolute;
  /* top: 42%; */
  z-index: 999;
  display: block;
  width: 7%;
  height: 100%;
  background: transparent;
  /* background: rgba(0,0,0,0.1); */
  color: #eee;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  border: none;
  outline: none;
  /* opacity: 0.8; */
  cursor: pointer;
}

#homeslider .control_prev:hover, button.control_next:hover {
  opacity: 1;
  -webkit-transition: all 0.2s ease;
  /* background-color: #fff; */
  color: #fff;
}

#homeslider .control_prev {
  border-radius: 0 4px 4px 0;
}

#homeslider .control_next {
  right: 0;
  border-radius: 4px 0 0 4px;
}

#newslidertext .bgimage .carousel-caption #slidersubtitle {
  margin-bottom: 9rem;
  /*padding-left: 5rem;*/
  margin-left: 3rem;
}

#newslidertext .bgimage .carousel-caption #slidersubtitle .main-title {
  margin-bottom: 2rem;
  color: black;
  font-weight: 700;
  font-size: 1.9rem;
}

// Success Stories

.success-stories {
  .item {
    .owl-height {
      height: 450px;
    }
    img {
      width: auto;
      height: 420px;
      border-radius: 20px;
    }

    .overlay-effect {
      position: absolute;
      width: 100%;
      height: 21.1%;
      top: 79%;
      left: 0;
      border-radius: 0 0 12px 12px;
      background-color: #fbd789;
      transition: 1s all ease-in-out 0s;
    }

    .content {
      width:100%;
      padding: 1rem;
      text-align: center;
      position: absolute;
      top: 81%;
      transition: 1s all ease-in-out 0s;
      h4 {
        color: #020202;
        text-align: center;
      }
      p {
        text-align: center;
        color: $white;
        transition: 1s all ease-in-out 0s;
      }
      .details {
        opacity: 0;
        transition: .5s ease-in-out;

        p {
          color: $dark;
        }
      }
    }

    &:hover {
      .overlay-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background-color: #fbd789db;
        transition: 1s all ease-in-out 0s;
      }

      .content {
        width:100%;
        padding: 1rem;
        text-align: center;
        position: absolute;
        bottom: 0;
        top: 0;
        transition: 1s all ease-in-out 0s;
        h4 {
          color: $dark;
          text-align: center;
        }
        p {
          text-align: center;
          color: $dark;
          transition: 1s all ease-in-out 0s;
          transition-delay: .5s;
        }
        .details {
          p {
            color: $dark;
          }
          opacity: 1;
          transition: .5s ease-in-out;
        }
      }
    }

  }
  #owl-1 .owl-stage-outer .owl-stage .owl-item .item {
    //padding-left: 2rem !important;
    //padding-right: 2rem !important;
  }

  #owl-1 .owl-nav button span {
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
  }

  #owl-1 .owl-theme .owl-nav .disabled {
    opacity: 0;
    cursor: default;
  }

  #owl-1 .owl-item img {
    position: relative;
  }

  #owl-1 .owl-item .item .details-block {
    background: #EED779;
    padding: unset;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    height: 150px;
  }

  #owl-1 .owl-item .item .details-block a {
    float: right;
    color: black;
    font-weight: 600;
    background: #69beb103;
    border: 2px solid #ffffff00;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  #owl-1 .owl-item .item .details-block h4 {
    font-weight: 700;
    background: #CBA950;
    margin-bottom: unset;
    padding: 1rem;
  }

  #owl-1 .owl-item .item .details-block .description {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    color: black;
    font-family: "american";
  }

  #owl-1 .owl-theme .owl-nav {
    margin-top: 0 !important;
  }

  #owl-1 .owl-nav button.owl-prev {
    transform: translate(0, -50%);
    left: -20px;
    top: calc(50% - 14px) !important;
  }

  #owl-1 .owl-nav button.owl-next {
    transform: translate(0, -50%);
    right: -20px;
    top: calc(50% - 14px) !important;
  }

  #owl-1 .owl-theme .owl-nav [class*=owl-]:hover {
    background: #FFFFFF !important;
    color: #FFF;
    text-decoration: none;

  }
}

/*.success-stories {
  .item {
    
    height: 493px;
    img {
      position: relative;
      width: 100%;
      height: 375px;
      border-radius: 20px 20px 0 0;
    }
    .overlay-effect {
      position: absolute;
      width: 100%;
     
      height: 37%;
      top: 63%;
      left: 0;
      border-radius: 0 0 20px 20px;
      background-color: #EED779;
      transition: 1s all ease-in-out 0s;
    }

    .content {
      width: 100%;
      padding: 0 0 1rem 0 ;
      text-align: center;
      position: absolute;
      top: 63%;
      left: 0;
      transition: 1s all ease-in-out 0s;
      h4 {
        color: $dark;
        text-align: center;
        background-color: #CBA950;
        padding: 1rem 1.5rem;
      }
      p {
        margin-top: 1rem;
        text-align: center;
        color: $dark;
        opacity: 1;
        transition: 1s all ease-in-out 0s;
      }
    }

    &:hover {
      .overlay-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background-color: $yellow-light;
        transition: 1s all ease-in-out 0s;
      }

      .content {
        width: 100%;
        padding: 1rem;
        text-align: center;
        position: absolute;
        bottom: 0;
        top: 0;
        transition: 1s all ease-in-out 0s;
        h4 {
          color: $dark;
          text-align: center;
          border-radius: 20px 20px 0 0;
        }
        p {
          margin-top: 0;
          text-align: center;
          color: $dark;
          opacity: 1;
          transition: 1s all ease-in-out 0s;
          transition-delay: .5s;
        }
      }
    }
  }

  #owl-1 .owl-stage-outer .owl-stage .owl-item .item {
    //padding-left: 2rem !important;
    //padding-right: 2rem !important;
  }

  #owl-1 .owl-nav button span {
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
  }

  #owl-1 .owl-theme .owl-nav .disabled {
    opacity: 0;
    cursor: default;
  }

  #owl-1 .owl-item img {
    position: relative;
  }

  #owl-1 .owl-item .item .details-block {
    background: #EED779;
    padding: unset;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    height: 150px;
  }

  #owl-1 .owl-item .item .details-block a {
    float: right;
    color: black;
    font-weight: 600;
    background: #69beb103;
    border: 2px solid #ffffff00;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  #owl-1 .owl-item .item .details-block h4 {
    font-weight: 700;
    background: #CBA950;
    margin-bottom: unset;
    padding: 1rem;
  }

  #owl-1 .owl-item .item .details-block .description {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    color: black;
    font-family: "american";
  }

  #owl-1 .owl-theme .owl-nav {
    margin-top: 0 !important;
  }

  #owl-1 .owl-nav button.owl-prev {
    transform: translate(0, -50%);
    left: -20px;
  }

  #owl-1 .owl-nav button.owl-next {
    transform: translate(0, -50%);
    right: -20px;
  }

  #owl-1 .owl-theme .owl-nav [class*=owl-]:hover {
    background: #FFFFFF !important;
    color: #FFF;
    text-decoration: none;

  }
}*/

// Milestones in Our Journey

#milestones .green-bg {
  background-color: #4E7FC1;
  padding: 1.5rem;
  border-radius: 1rem;
  color: #ffffff;
}

#milestones{
  #milestonesblock{
  position: relative;
  overflow: hidden;

:after {
  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -400%;
  width: 400%;
  height: 50%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

/* Hover state - trigger effect */


/* Active state */

:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
  }
}

// All banner size

.pb-25 {
  padding-bottom: 25rem !important;
}
  @media screen and (max-width: 4558px){
    .pb-41 {
      padding-bottom: 136rem !important;
    }
  }
  @media screen and (max-width: 2400px) {
    //For Big TV's (HD Screens)
      .pb-41{
        padding-bottom: 69rem !important;
      }
  }
  @media screen and (max-width: 1500px){
    .pb-41 {
      padding-bottom: 41rem !important;
    }
  }

  @media screen and (max-width: 1200px){
    .pb-41 {
      padding-bottom: 34rem !important;
    }
  }

    @media screen and (max-width: 1024px) {
      //For Big TV's (HD Screens)
        .pb-41{
          padding-bottom: 26rem !important;
        }
      } 

@media only screen and (max-width: 600px) {
  .pb-41 {
    padding-bottom: 5rem !important;
  }
  .pb-25 {
    padding-bottom: 5rem !important;
  }
}
// Our journey

.journey {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  margin-bottom: 2.5rem;
}

.journey > .card {
  width: 100%;
  min-width: 100%;
  border-radius: 1rem;
  border: 0;
  background: #fff;
  border: 5px solid lightgrey;
}

.journey > .card .card-body {
  padding: 1rem 1rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.journey > .card .card-body h4.font-weight-normal.mb-3 {
  font-size: large;
}

.journey > .card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.journey > .card .card-body .mb-3 {
  margin-bottom: 1rem !important;
}

.journey .card.card-img-holder {
  position: relative;
}

.journey .purchase-popup {
  margin-bottom: 2.5rem;
}

.bg-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#AF5926), color-stop(99%, #DE8B43)) !important;
  background: linear-gradient(to right, #AF5926, #DE8B43 99%) !important;
}

.bg-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#CBA950), to(#EED779)) !important;
  background: linear-gradient(to right, #CBA950, #EED779) !important;
}

.bg-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#9FD0EF), to(#4E7FC1)) !important;
  background: linear-gradient(to right, #9FD0EF, #4E7FC1) !important;
}

.bg-gradient-green {
  background: -webkit-gradient(linear, left top, right top, from(#A02F22), to(#D66359)) !important;
  background: linear-gradient(to right, #A02F22, #D66359) !important;
}

.bg-gradient-yellow {
  background: -webkit-gradient(linear, left top, right top, from(#4e4e4e), to(#7d7d7d)) !important;
  background: linear-gradient(to right, #4e4e4e, #7d7d7d) !important;
}

.bg-gradient-purple {
  background: -webkit-gradient(linear, left top, right top, from(#2D5550), to(#69BEB1)) !important;
  background: linear-gradient(to right, #2D5550, #69BEB1) !important;
}

// Apply online 

#applyonline textarea.form-control {
  height: 124px;
}

// Admission Process

.w-95 {
  width: 95% !important;
  border-radius: 22px;
}

// Donate

#donateform .card-header {
  color: black;
  background-color: white;
  border-top-right-radius: 26px;
  border-top-left-radius: 26px;
  display: flex;
  padding: 1.7rem 1.7rem;
}

#donateform .card-header .w-5 {
  width: 6% !important;
}

#donateform {
  border-radius: 26px;
  margin-left: 18%;
  margin-right: 18%;
  width: auto;
}

#donateform .form-control {
  padding: unset;
  border-color: #000000;
  color: #000000;
  border-radius: 10px;
  width: 64%;
  padding: .5em;
}

#donateform .card-header .card .card-title {
  margin-top: 0.75rem;
}

#donateform .card-body .form-group h5 {
  font-size: xx-large;
}

.donorlable {
  padding-right: 3rem;
}

#donationform {
  display: none;
}

#donorsection .custom-control-inline {
  margin-right: 4rem;
}

#donationform .form-control {
  border-color: #69BEB1;
  color: #000000;
  border-radius: unset;
  width: 100%;
  padding: 1.5rem 1rem;
}

#donationform .form-group .label-label {
  font-size: 13px;
  color: #69BEB1;
  top: -13px;
}
#donationblock{
  .success-stories{
    .topsection{
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
    #donateform{
      #priceblock{
        position: relative;
        #cur{
          font-weight: 600;
          position: absolute;
          right: 8em;
          top: 2.5em;
          z-index: 9;
        }
      }
    }
  }

.top-donors{
  margin-top: -4rem;
.card{
    padding: 1rem;
    border-radius: 1rem;
    background-color: #ffffff;
  h1{
    text-align: center;
    color: black;
    margin: unset;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e6e7e8;
    font-size: x-large;
  }
  .showmore{
    text-align: center;
    padding-top: 2rem;
  }
}
.notifications__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 77px;
  /*padding: 0 20px;*/
  border-bottom: 1px solid #e6e7e8;
  background-color: white;
  /*box-shadow: 0px 15px 20px 0px rgb( 0, 0, 0, .2 );*/
  transition: all .3s ease-in;
  cursor: pointer;
}

.notifications__item__avatar {
  width: 51px;
  height: 49px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 50%;
  .cart-count {
    padding: 1.2px;
    font-size: 9pt;
    color: #ffffff;
    background-color: #d66359;
    position: absolute;
    left: 3.2rem;
    /*top: 0.2rem;*/
    min-height: 1.2rem;
    max-height: 1.2rem;
    min-width: 1.2rem;
    text-align: center;
    border-radius: 50%;
    font-size: 11px;
    line-height: 17px;
  }
  .cart-count1 {
    padding: 1.2px;
    font-size: 9pt;
    color: #ffffff;
    background-color: #4e7fc1;
    position: absolute;
    left: 3.2rem;
    /*top: 0.2rem;*/
    min-height: 1.2rem;
    max-height: 1.2rem;
    min-width: 1.2rem;
    text-align: center;
    border-radius: 50%;
    font-size: 11px;
    line-height: 17px;
  }
  .cart-count3 {
    padding: 1.2px;
    font-size: 9pt;
    color: #ffffff;
    background-color: #69beb1;
    position: absolute;
    left: 3.2rem;
    /*top: 0.2rem;*/
    min-height: 1.2rem;
    max-height: 1.2rem;
    min-width: 1.2rem;
    text-align: center;
    border-radius: 50%;
    font-size: 11px;
    line-height: 17px;
  }
}

.notifications__item__avatar img {
  width: 100%;
  height: 100%;
}

.notifications__item__content { width: calc( 100% - 105px ); }
.notifications__item__title,
.notifications__item__message { display: block; }

.notifications__item__title {
  letter-spacing: 2px;
  font-size: 12px;
}

.notifications__item__message {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: #929292;
}

.notifications__item__option {
  /*width: 20px;
  height: 20px;
  margin: 8px 0;*/
  color: black;
  font-size: 10px;
  text-align: end;
  line-height: 20px;
  cursor: pointer;
  transition: all .2s;
}

.notifications__item #price {
  color: #69beb1 !important;
  font-weight: 800 !important;
}

}
}

@media only screen and (max-width: 600px) {
  #donationblock .success-stories .topsection {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  #donateform {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

//header

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

div#sticky {
  z-index: 99999;
}

.stickyactive {
  background: #69beb1;
}

// search bar

.togglesearch {
  background: #E8E8E4;
  position: absolute;
  top: 74px;
  right: 22%;
  width: 350px;
  height: 50px;
  line-height: 42px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-top: 4px solid #69beb1;
  display: none;
}

.togglesearch:before {
  content: "";
  position: absolute;
  top: -30px;
  right: 13px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 14px solid transparent;
  border-bottom: 14px solid #69beb1;
}

.togglesearch input[type="text"] {
  width: 277px;
  border: 1px solid #69beb1;
  outline: none;
}

.togglesearch input[type="button"] {
  width: 74px;
  background: #d66359;
  color: #fff;
  margin-left: -6px;
  border: 1px solid #d66359;
  outline: none;
  cursor: pointer;
}

#ourvision {
  background: linear-gradient( to bottom, #ffffff 0%, #ffffff 36%, #F9F9F9 21%, #F9F9F9 53% ) !important;
}

#ourvision #vissionsection {
  /*margin-top: -11.5rem !important;*/
}

#ourvision #missionsection {
  margin-top: -2.5rem !important;
}

#ourvision #valuessection {
  /*margin-top: -11.5rem !important;*/
}

@media (max-width: 1024px) {
  #ourvision {
    background-color: #F9F9F9 !important;
    background-size: 50% !important;
    margin: 5rem 0 0 0 !important;
    padding: unset !important;
  }
  #ourvision {
    margin-top: 7rem !important;
  }
  #ourvision #vissionsection {
    margin-top: unset !important;
  }
  #ourvision #missionsection {
    margin-top: unset !important;
  }
  #ourvision #valuessection {
    margin-top: unset !important;
  }
  #ourvision #vissionsection {
    background-color: #4E7FC1;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #ourvision #missionsection {
    background-color: #fbd789;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #ourvision #valuessection {
    background-color: #D66359;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
}

// case studies

#casestudiessection {
  .green-bg {
    background-color: #ca9f35;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #caseheader {
    .card {
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
  }
  #casestudies {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;

    .card-body {
      background: #f6cd5b;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
    }
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      .card-title {
        padding: 1rem;
        background: #ca9f35;
        margin-bottom: unset;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
      }
      a {
        float: right;
        color: #D66359;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// Events

#eventssection {
  .green-bg {
    background-color: #4E7FC1;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #eventsheader {
    .card {
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
  }
  #events {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;

    .card-body {
      background: #9FD0EF;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
    }
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      .card-title {
        padding: 1rem;
        background: #4E7FC1;
        margin-bottom: unset;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
      }
      a {
        float: right;
        color: white;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// Newsletters

#newsletterssection {
  .green-bg {
    background-color: #EED779;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #newsletterheader {
    .card {
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
  }
  #newsletter {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;

    .card-body {
      background: #EED779;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
    }
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      .card-title {
        padding: 1rem;
        background: #CBA950;
        margin-bottom: unset;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
      }
      a {
        float: right;
        color: white;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// News & Media Coverage

#newsmediasection {
  .green-bg {
    background-color: #69BEB1;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #newsmediaheader {
    .card {
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
  }
  #newsmedia {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;

    .card-body {
      background: #69BEB1;
      color: white;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
    }
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      .card-title {
        padding: 1rem;
        background: #2D5550;
        margin-bottom: unset;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        color: white;
      }
      a {
        float: right;
        color: white;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// Blog

#blogsection {
  .green-bg {
    background-color: #D66359;
    padding: 1.5rem;
    border-radius: 1rem;
    color: #ffffff;
  }
  #blogheader {
    .card {
      margin-left: 18%;
      margin-right: 18%;
      width: auto;
    }
  }
  #blog {
    border: 1px solid #b9c0cf00;
    margin-left: 9%;
    margin-right: 9%;

    .card-body {
      background: #D66359;
      color: white;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      padding: unset;
    }
    .card-img-top {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
    .card-body {
      .card-title {
        padding: 1rem;
        background: #A02F22;
        margin-bottom: unset;
      }
      .card-text {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        color: white;
      }
      a {
        float: right;
        color: white;
        font-weight: 600;
        background: #69beb103;
        border: 2px solid #ffffff00;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// Description Popup

@media (min-width: 576px) {
  #descriptionpopup {
    max-width: 900px !important;
  }
}

// Our Team

.popupcard {
  z-index: 99999999999999999;
  .modal-dialog {
    max-width: 700px;
    .modal-content {
      width: 100% !important;
      background: #ebc760 !important;
      color: #fff !important;
      .modal-header {
        border-bottom: 1px solid #ffffff;
        h3{
          border-right: 3px solid $white;
          padding-right: .5rem;
        }
        p{
          margin-bottom: 0;
          line-height: 1.5;
          padding: 8px 0 0 22px;
        }
        .close {
          float: right;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1;
          color: #fff;
          text-shadow: inset;
          opacity: unset;
        }
      }
      .modal-body {
        padding: 40px;
        .modalphoto {
          width: 174px;
          height: 175px;
          float: left;
          border-radius: 50%;
          overflow: hidden;
          .img-fluid {
            height: 217px;
          }
        }
        .modaltext {
          width: calc(100% - 200px);
          float: left;
          margin-left: 25px;
          line-height: 25px;
          font-family: 'robotolight' !important;
          font-size: 18px !important;
        }
      }
    }
  }
}

.the-team {
  .item {
    .owl-height {
      height: 450px;
    }
    img {
      width: auto;
      height: 420px;
      border-radius: 20px;
    }

    .overlay-effect {
      position: absolute;
      width: 100%;
      height: 21.1%;
      top: 79%;
      left: 0;
      border-radius: 0 0 12px 12px;
      background-color: $danger-light;
      transition: 1s all ease-in-out 0s;
    }

    .content {
      width:100%;
      padding: 1rem;
      text-align: center;
      position: absolute;
      top: 81%;
      transition: 1s all ease-in-out 0s;
      h4 {
        color: $white;
        text-align: center;
      }
      p {
        text-align: center;
        color: $white;
        transition: 1s all ease-in-out 0s;
      }
      .details {
        opacity: 0;
        transition: .5s ease-in-out;

        p {
          color: $dark;
        }
      }
    }

    &:hover {
      .overlay-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background-color: #fbd789db;
        transition: 1s all ease-in-out 0s;
      }

      .content {
        width:100%;
        padding: 1rem;
        text-align: center;
        position: absolute;
        bottom: 0;
        top: 0;
        transition: 1s all ease-in-out 0s;
        h4 {
          color: $dark;
          text-align: center;
        }
        p {
          text-align: center;
          color: $dark;
          transition: 1s all ease-in-out 0s;
          transition-delay: .5s;
        }
        .details {
          p {
            color: $dark;
          }
          opacity: 1;
          transition: .5s ease-in-out;
        }
      }
    }

  }
}

.our-advisors {
  .item {
    .owl-height {
      height: 450px;
    }
    img {
      width: auto;
      height: 420px;
      border-radius: 20px;
    }

    .overlay-effect {
      position: absolute;
      width: 100%;
      height: 21.1%;
      top: 79%;
      left: 0;
      border-radius: 0 0 12px 12px;
      background-color: $success-light;
      transition: 1s all ease-in-out 0s;
    }

    .content {
      width:100%;
      padding: 1rem;
      text-align: center;
      position: absolute;
      top: 81%;
      transition: 1s all ease-in-out 0s;
      h4 {
        color: $white;
        text-align: center;
      }
      p {
        text-align: center;
        color: $white;
        transition: 1s all ease-in-out 0s;
      }
      .details {
        p {
          color: $dark;
        }
        opacity: 0;
        transition: .5s ease-in-out;
      }
    }

    &:hover {
      .overlay-effect {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background-color: #fbd789db;
        transition: 1s all ease-in-out 0s;
      }

      .content {
        width:100%;
        padding: 1rem;
        text-align: center;
        position: absolute;
        bottom: 0;
        top: 0;
        transition: 1s all ease-in-out 0s;
        h4 {
          color: $dark;
          text-align: center;
        }
        p {
          margin-top: 0;
          text-align: center;
          color: $dark;
          opacity: 1;
          transition: 1s all ease-in-out 0s;
          transition-delay: .5s;
        }
        .details {
          p {
            color: $dark;
          }
          opacity: 1;
          transition: .5s ease-in-out;
        }
      }
    }

  }
}

@media (max-width: 480px) {
  .popupcard .modal-dialog .modal-content .modal-body .modalphoto {
    float: none;
    margin: 10px auto;
  }
  .popupcard .modal-dialog .modal-content .modal-body .modaltext {
    width: 100%;
    text-align: center;
    margin: auto;
  }
}

// Details Page
@media only screen and (max-width: 600px) {
  #alldeatilspage .overlay-text {
    width: 100% !important;
    right: auto !important;
  }
  #alldeatilspage .col .row{
    flex-direction: column-reverse;
  }
}
#alldeatilspage {
  .overlay-text {
    /* font-size: 3.5vw; */
    float: right;
    width: 30%;
    bottom: 2vw;
    padding: 15px;
    background: #f7f7f7;
    right: -16px;
    height: auto;
    p {
      font-size: small;
      text-align: justify;
      font-weight: 500;
      color: #7d7f92;
    }
  }
  .hover-panel {
    background-color: #111;
    background-size: cover;
    background-position: 50%;
    overflow: hidden;
    color: #fefefe;
    height: 60%;
    min-height: 400px;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: relative;
    margin-top: -6px;

    .hover-panel-title {
      align-self: center;
      z-index: 10;
    }
    .overlay-dark {
      background: rgba(0, 0, 0, .5);
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      -webkit-transition: opacity 1s;
      transition: opacity 1s;
    }
    .overlay-blue {
      background: rgba(37, 110, 229, .8);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      -webkit-transition: opacity 1s;
      transition: opacity 1s;

      &:hover .overlay-blue {
        opacity: 1;
      }
    }
  }
}
.hover-panel {
  background-color: #111;
  background-size: cover;
  background-position: 50%;
  overflow: hidden;
  color: #fefefe;
  height: 60%;
  min-height: 400px;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  margin-top: -6px;

  .hover-panel-title {
    align-self: center;
    z-index: 10;
}
.overlay-dark {
  background: rgba(0,0,0,.5);
  opacity: 1;
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}
.overlay-blue {
  background: rgba(37,110,229,.8);
  opacity: 0;
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  
  :hover .overlay-blue {
    opacity: 1;
}
}
}

//Careers page Vacany Box start

#careers-card{
  .list-group {
    border: 1px solid #b6b3b3;
    border-radius: 1rem;
      .list-group-item {
        border: none;
        font-family: "american" !important;
      }
    .list-group-item.active {
      color: #ffffff;
      background-color: #4E7FC1;
    }
  }
}

p, span, a, li {
  font-family: "american" !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "bradley" !important;
}

// Login Model

.login-model {
  .modal-content {
    background-color: $yellow-light;

    .modal-header {
      border-bottom: $white;
    }

    .modal-body {
      font-family: "american" !important;

      .form-control {
        background-color: Transparent;
        border-color: $dark;
      }
    }

  }
}

// Floating Social Icons
.solial-float-icon {
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 50%;
  z-index: 9999999;
  ul {
    li {
      list-style: none;
      display: block;
      margin-bottom: 1.8rem;

      a {
        padding: 1rem;
        background-color: $yellow-light;
        color: $dark;
        transition: .5s ease-in-out;
        border-radius: 10px 0 0 10px;
        transition: .5s ease-in-out;


        &:hover {
          background-color: $dark;
          color: $white;
          transition: .5s ease-in-out;
        }
      }
    }
  }
}


 // Hall of Philanthropy

 .hall-of-philanthropy {
  .hero {
    /*background: url('../img/new/help-raise1.png') no-repeat;*/
    background-size: 100%;
    padding-bottom: 11rem;
  }
  .main-carousel {
    .main-title {
      @include font($white, 35pt, 500);
    }

    .main-sub {
      @include font($white, 13pt, 500);
    }

  }
  .top-donors{
      margin-top: -4rem;
    .card{
        padding: 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
      h1{
        text-align: center;
        color: black;
        margin: unset;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e6e7e8;
        font-size: x-large;
      }
      .showmore{
        text-align: center;
        padding-top: 2rem;
      }
    }
    .notifications__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 77px;
      /*padding: 0 20px;*/
      border-bottom: 1px solid #e6e7e8;
      background-color: white;
      /*box-shadow: 0px 15px 20px 0px rgb( 0, 0, 0, .2 );*/
      transition: all .3s ease-in;
      cursor: pointer;
    }
    
    .notifications__item__avatar {
      width: 51px;
      height: 49px;
      overflow: hidden;
      margin-right: 20px;
      border-radius: 50%;
      .cart-count {
        padding: 1.2px;
        font-size: 9pt;
        color: #ffffff;
        background-color: #d66359;
        position: absolute;
        left: 3.2rem;
        /*top: 0.2rem;*/
        min-height: 1.2rem;
        max-height: 1.2rem;
        min-width: 1.2rem;
        text-align: center;
        border-radius: 50%;
        font-size: 11px;
        line-height: 17px;
      }
      .cart-count1 {
        padding: 1.2px;
        font-size: 9pt;
        color: #ffffff;
        background-color: #4e7fc1;
        position: absolute;
        left: 3.2rem;
        /*top: 0.2rem;*/
        min-height: 1.2rem;
        max-height: 1.2rem;
        min-width: 1.2rem;
        text-align: center;
        border-radius: 50%;
        font-size: 11px;
        line-height: 17px;
      }
      .cart-count3 {
        padding: 1.2px;
        font-size: 9pt;
        color: #ffffff;
        background-color: #69beb1;
        position: absolute;
        left: 3.2rem;
        /*top: 0.2rem;*/
        min-height: 1.2rem;
        max-height: 1.2rem;
        min-width: 1.2rem;
        text-align: center;
        border-radius: 50%;
        font-size: 11px;
        line-height: 17px;
      }
    }
    
    .notifications__item__avatar img {
      width: 100%;
      height: 100%;
    }
    
    .notifications__item__content { width: calc( 100% - 105px ); }
    .notifications__item__title,
    .notifications__item__message { display: block; }
    
    .notifications__item__title {
      letter-spacing: 2px;
      font-size: 12px;
    }
    
    .notifications__item__message {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #929292;
    }
    
    .notifications__item__option {
      /*width: 20px;
      height: 20px;
      margin: 8px 0;*/
      color: black;
      font-size: 10px;
      text-align: end;
      line-height: 20px;
      cursor: pointer;
      transition: all .2s;
    }

    .notifications__item #price {
      color: #69beb1 !important;
      font-weight: 800 !important;
    }
    
    /*.notifications__item__option.archive { background-color: #3dc98c; }
    
    .notifications__item__option.delete { background-color: #c93d4d; }*/
  }
}

 // Document-required

.document-req {
  ul {
    padding-inline-start: 1.1rem;

    li {
      padding-bottom: .5rem;
    }
  }
}


// New Link page

#link-design{
  .link-design-block{
    .item{
      padding-left:5px;
      padding-right:5px;
    }
    .item{
      padding-left:5px;
      padding-right:5px;
    }
    .item-card{
      transition:0.5s;
      cursor:pointer;
    }
    .item-card{
      .linkimage{
        height:200px;
        width:100%;
      }
      .btn-primary {
        color: #000000;
        background-color: #fbd789;
        border-color: #fbd789;
      }
    }
    .item-card-title i{  
      font-size:15px;
      transition:1s;
      cursor:pointer;
      color:#ffa710
    }
    
    .card-title i:hover{
      transform: scale(1.25) rotate(100deg); 
      color:#18d4ca;
      
    }
    .card:hover{
      transform: scale(1.05);
      box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
    }
    .card-text{
      height:80px;
      padding-left: 1rem;
      padding-right: 1rem;  
    }
    
    .card::before, .card::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale3d(0, 0, 1);
      transition: transform .3s ease-out 0s;
      background: rgba(255, 255, 255, 0.1);
      content: '';
      pointer-events: none;
    }
    .card::before {
      transform-origin: left top;
    }
    .card::after {
      transform-origin: right bottom;
    }
    .card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
      transform: scale3d(1, 1, 1);
    }
  }
}

#link-design1{
  #donateform .form-control {
    padding: 9px;
    border-color: #000000;
    color: #000000;
    border-radius: 10px;
    width: 100%;
  }
}

#link-design2{
  #donateform {
    .form-control {
      padding: 9px;
      border-color: #000000;
      color: #000000;
      border-radius: 10px;
      width: 100%;
    }
    .btn-primary{
      color: #ffffff;
      background-color: #1C1C1C;
      border-color: #1C1C1C;
      font-size: small;
      line-height: 1.5;
      padding: 8px 11px 8px 11px;
      border-radius: 5px;
    }
    #circ {
      max-width: 100%;
      width: 1280px;
      text-align: center;
      margin: 0 auto;
      float: none;
      ul {
        list-style: none;
        padding: 0;
        width: 100%;
        li {
          display: inline-block;
          border-radius: 50%;
          overflow: hidden;
          width: 60px;
          height: 60px;
          margin: 0 5px 9px 4px;
          text-align: center;
           background: #fff;
           border: solid 4px #48837a;
          position: relative;
          -webkit-transition: all 0.4s;
          -moz-transition: all 0.4s;
          transition: all 0.4s;
          :hover {
            border: solid 8px black;
            background: black;
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            transition: all 0.4s;
            cursor: pointer;
          }
          .icon {
            position: absolute;
            width: 100%;
            line-height: 55px;
            display: block;
            color: #48837a;
            font-size: 2em;
            -webkit-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            -moz-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          .icon:hover {
            color: white;
            font-size: x-large;
            line-height: 41px;
          }
        }
        .delayone {
          -moz-animation-delay: .2s;
          -webkit-animation-delay: .2s;
        }
        
        .delaytwo {
          -moz-animation-delay: .4s;
          -webkit-animation-delay: .4s;
        }
        
        .delaythree {
          -moz-animation-delay: .6s;
          -webkit-animation-delay: .6s;
        }
        
        .delayfour {
          -moz-animation-delay: .8s;
          -webkit-animation-delay: .8s;
        }
        
        .delayfive {
          -moz-animation-delay: 1s;
          -webkit-animation-delay: 1s;
        }
        
        .delaysix {
          -moz-animation-delay: 1.4s;
          -webkit-animation-delay: 1.4s;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {

  #circ ul li .icon {
    position: relative;
    color: #004d40;
    font-size: 2em;
    -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  #circ ul li {
    width: 80px;
    height: 80px;
    margin: 0 5px 5px 5px;
  }
  #circ ul li:hover .icon {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 690px) {
  #circ ul li {
    border-radius: 0;
    border: none;
    margin: 0;
  }
  #circ ul li:hover {
    border: none;
  }
  #link-design2 #donateform .btn-primary {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  #link-design2 #donateform .btn-primary {
    margin-top: unset;
  }
}
@media only screen and (max-width: 500px) {
  #link-design2 #donateform .btn-primary {
    margin-top: 1rem;
  }
}

#link-design3{
  #donateform {
    .card-header{
      border-top-right-radius: 26px;
      border-top-left-radius: 26px;
      border-bottom-right-radius: 26px;
      border-bottom-left-radius: 26px;
      border-bottom: none;
    }
    .linkimage{
      width: inherit;
      height: fit-content;
    }
  }
}

// Login Count page css start

#logincount{
  #whatwedo{
    .line-through-center {
      margin-right: unset;
      margin-left: 3rem;
    }
    #datesection .title{
      text-align: end;
    }
  }
}

@media only screen and (max-width: 991px) {
  #donationblock .success-stories #donateform #priceblock #cur {
    right: 5em;
  }
}

@media only screen and (max-width: 768px) {
  #donationblock .success-stories #donateform #priceblock #cur {
    right: 5em;
  }
}

@media only screen and (max-width: 600px) {
  #logincount{
    .line-through-center {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    #datesection{
      margin-top: unset !important;
    }
    .title{
      text-align: center !important;
    }
  }
  #donationblock .success-stories #donateform #priceblock #cur {
    right: 11em;
  }
}

@media only screen and (max-width: 467px) {
  #donationblock .success-stories #donateform #priceblock #cur {
    right: 8em;
  }
}

#quartersfildes .col-form-label{
  padding-top: unset;
}